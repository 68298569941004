import React from "react";
import ReactMarkdown from 'react-markdown'
import { Duree, ProcessList, ProcessItem, ProcessHeadImage, TimeLine } from "./styles";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const Processus = ({
  processus,
  titleProcessus
}) => {
  const images = processus.map((p) => (
    getImage(p.image.image) || p.image.image
  ));
  return (
    <>
      <h3>
        {titleProcessus}
      </h3>
      <TimeLine>
        <hr/>
        <span className="top_arrow"></span>
        <span className="bottom_arrow"></span>
      </TimeLine>
      <Duree position="subtitle">
             De 3 à 12 mois   
      </Duree>
      <ProcessList>
        {processus.map((p, i) => (
          <ProcessItem key={i}>
            <ProcessHeadImage>
              <h4>
                {p.title}
              </h4>
              <div className="image">
                <GatsbyImage image={images[i]} alt={p.image.alt} title={p.image.alt} />
              </div>
            </ProcessHeadImage>
            <h4>
              {p.subtitle}
            </h4>
            <ReactMarkdown className="text">
              {p.body}
            </ReactMarkdown>
          </ProcessItem>
        ))}
      </ProcessList>
    </>
  );
}

export default Processus;