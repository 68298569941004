import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { HeaderImageContainer } from "./styles";

export default function HeaderImage(props) {
  const {
    img,
    imgPosition = "bottom left",
  } = props;

  return (
    <HeaderImageContainer to="/a-propos">
      {img?.url ? (
        <img
          src={img}
          objectFit={"cover"}
          objectPosition={imgPosition}
          // style={{
          //   width: "100%",
          // }}
          // This is a presentational image, so the alt should be an empty string
          alt=""
        />
      ) : (
        <GatsbyImage
          image={img}
          objectFit={"cover"}
          objectPosition={imgPosition}
          layout="fullWidth"
          // You can optionally force an aspect ratio for the generated image
          aspectratio={1 / 3}
          // This is a presentational image, so the alt should be an empty string
          alt=""
          formats={["auto", "webp", "avif"]}
        />
      )}
    </HeaderImageContainer>
  );
}

HeaderImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
