import React from "react";
import PropTypes from "prop-types";
import { graphql } from 'gatsby';
import Layout from "../components/Core/Layout";

import { IndexPageComponent } from "../components/Pages/Index";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  smallImage,
  btnLink,
  btnLink2,
  blogue,
  titleProcessus,
  processus,
  services,
  title,
  title2,
}) => {
  
  return (
    <IndexPageComponent 
      image={image}
      smallImage={smallImage}
      btnLink={btnLink}
      btnLink2={btnLink2}
      blogue={blogue}
      titleProcessus={titleProcessus}
      processus={processus}
      services={services}
      title={title}
      title2={title2}
    />
  );
};

IndexPageTemplate.propTypes = {
  
  // TODO
  processus: PropTypes.array,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        smallImage={frontmatter.smallImage}
        btnLink={frontmatter.btnLink}
        btnLink2={frontmatter.btnLink2}
        blogue={frontmatter.blogue}
        titleProcessus={frontmatter.titleProcessus}
        processus={frontmatter.processus}
        services={frontmatter.services_index}
        title={frontmatter.title}
        title2={frontmatter.title2}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export { Head } from "../components/Core/HtmlHead";

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      html
      frontmatter {
        processus {
          image {
            ...imageProcessusBlock
          }
          body
          title
          subtitle
        }
        btnLink {
          btnLink
          btnLabel
          isAction
        }
        btnLink2 {
          btnLink
          btnLabel
          isAction
        }
        titleProcessus
        blogue {
          body
          btnLink {
            btnLink
            btnLabel
            isAction
          }
          image {
            ...imageBlogueBlock
          }
          title
        }
        title2
        title
        services_index {
          title
          body
          btnLink {
            btnLink
            btnLabel
            isAction
          }
        }
        image {
          ...indexImageBlock
        }
        smallImage: image {
          ...indexImageSmallBlock
        }
      }
    }
  }
`;


// image {
//   image {
//     childImageSharp {
//       gatsbyImageData(quality: 100, layout: FULL_WIDTH)
//     }
//   }
// }