import React, { useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { getImage, withArtDirection } from "gatsby-plugin-image";
import { Header, HeaderContent, IntroVideo, IntroVideoContainer, ServicesTitle, SerivcesContent } from "./styles";
import { ButtonLink, Section, TopTitle, TopSubtitle, ButtonsBar } from "../../styles";
import HeaderImage from "./HeaderImage";
import Processus from "./Processus";
import { GatsbyImage } from "gatsby-plugin-image";
import { useContext } from "react";
import ShowIntroContext from "../../../context/showIntro";
import useWindowSize from "../../../hooks/useWindowSize";


export const IndexPageComponent = ({
  image,
  smallImage,
  btnLink,
  btnLink2,
  blogue,
  titleProcessus,
  processus,
  services,
  title,
  title2
}) => {

  const {showIntro, setShowIntro} = useContext(ShowIntroContext);
  const { width } = useWindowSize();

  const img = withArtDirection(getImage(image.image), [
    {
      media: "(max-width: 1024px)",
      image: getImage(smallImage.image),
    },
  ]) || image.image;
  const blogImg = getImage(blogue.image.image) || blogue.image.image;

  useEffect(() => {
    if(typeof document !== 'undefined'){
      const video = document.getElementById("introVideo");
      if(video){
        video.onended = () => {
          setShowIntro(false);
        };
        video.onerror = () => {
          setShowIntro(false);
        }
      }
    }
  }); 

  return (
    <>
      {showIntro === true && (
        <IntroVideoContainer onClick={() => setShowIntro(false)}>
          <IntroVideo id="introVideo" playsinline autoPlay="1" muted>
            {width <= 1024 && (<source src="/img/intro_mobile.webm" type="video/webm" />)}
            {width > 1024 && (<source src="/img/intro.webm" type="video/webm" />)}
          </IntroVideo>
        </IntroVideoContainer>
      )}
      <Header>
        <HeaderContent>
          <TopTitle variant="accueil">
            {title}
          </TopTitle>
          <TopSubtitle>
            <ReactMarkdown>
              {title2}
            </ReactMarkdown>
          </TopSubtitle>
          <ButtonsBar>
          <ButtonLink to={btnLink.btnLink} isAction={btnLink.isAction}>
            {btnLink.btnLabel}
          </ButtonLink>
          </ButtonsBar>
        </HeaderContent>
        <HeaderImage img={img} alt={image.alt}/>
      </Header>
      <Section variant="white">
        <Processus processus={processus} titleProcessus={titleProcessus} />
        <ButtonsBar>
          <ButtonLink className="white" to={btnLink2.btnLink} isAction={btnLink2.isAction}>
              {btnLink2.btnLabel}
            </ButtonLink>
        </ButtonsBar>
      </Section>
      <Section variant="services">
        <ServicesTitle>
          {services.title}
        </ServicesTitle>
        <SerivcesContent>
          <ReactMarkdown>
            {services.body}
          </ReactMarkdown>
          <ButtonLink to={services.btnLink.btnLink} isAction={services.btnLink.isAction}>
              {services.btnLink.btnLabel}
            </ButtonLink>
        </SerivcesContent>
      </Section>
      <Section variant="blogue">
        <div className="content">
          <div className="text">
            <h3 className="blogue_title">
              {blogue.title}
            </h3>
            <ReactMarkdown>
              {blogue.body}
            </ReactMarkdown>
            <ButtonLink to={blogue.btnLink.btnLink} isAction={blogue.btnLink.isAction}>
              {blogue.btnLink.btnLabel}
            </ButtonLink>
          </div>
          <div className="image">
            <a rel="noreferrer" href="https://podcast.ausha.co/allo-anais" target="_blank">
              <GatsbyImage image={blogImg} alt={blogue.image.alt} title={blogue.image.alt} />
            </a>
          </div>
        </div>
      </Section>
    </>
  );
}