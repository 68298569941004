import styled from "styled-components";
import { Link } from "gatsby";

export const IntroVideoContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1999;
  background: black;
  @supports (-webkit-touch-callout: none) {
    display:none;
  }
`;

export const IntroVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2000;
  width: 100%;
  min-height: 100%;
  @media (min-width: 1024px) {
    width: inherit;
    min-width: 100%;
  }
`;

export const Header = styled.header`
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding-top: 60px;
  background: url(/img/Al.svg) no-repeat left top fixed;
  background-color: ${props => props.theme.colors.orange};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  min-height: 90vh;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    background-image: url(/img/Al_m.svg);
  }
`;

export const HeaderContent = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  @media (min-width: 1024px) {
    padding-left: 40px;
    align-items: flex-start;
  }
`;

export const HeaderImageContainer = styled(Link)`
  display: flex;
  align-self: flex-end;
  & > div{
    border-radius: 219.636px 0px 0px 0px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  @media (min-width: 1024px) {
    & > div{
      border-radius: 310px 0px 0px 0px;
    }
  }
`;

export const Duree = styled.span`
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  @media (min-width: 1024px) {
    display:flex;
    position: absolute;
    right: 7em;
    top: 51ex;
  }
`;
export const TimeLine = styled.div`
display:flex;
flex-direction:row;
position: relative;
top: 200px;
&::after, &::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 2px;
  right: 4.8%;
  background-color: #000;
}

&::after {
  top: -5px;
  transform: rotate(45deg);
}

&::before {
  top: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  transform: rotate(-45deg);
}

& > hr {
    border-width: 1px;
    border-color: black;
    width: 90%;
    margin: auto;
    height: 1px;
}
 @media (max-width: 1024px) {
    display:none;
  }
`
export const ProcessList = styled.ul`
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  @media (max-width: 1024px) {
    position: relative;
    display: block;
    border-left: 1px solid black;
    &::after, &::before {
      content: '';
      position: absolute;
      width: 14px;
      height: 1px;
      right: 4.8%;
      background-color: #000;
    }
    
    &::after {
      bottom: 5px;
      left: -13px;
      transform: rotate(45deg);
    }
    
    &::before {
      bottom: 5px;
      left: -2px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
      transform: rotate(-45deg);
    }
  }
`;

export const ProcessItem = styled.li`
  display: flex;
  flex-direction: column;
  width:218px;
  margin: 2.1875rem;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  & > h4{
    margin-bottom: 50px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 20px;
    width: 90%;
    & > h4{
      margin-bottom: 10px;
    }
  }
`;

export const ProcessHeadImage = styled.div`
  display:flex;
  align-items: flex-end;
  min-height: 12.2ex;
  & > h4 {
    margin-right: 35px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 10px;
    & > h4 {
      margin-right: 20px;
      margin-left: -30px;
    }
  }
`

export const ServicesTitle = styled.h3`
  margin-right:6.125rem;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 70px;
  @media (max-width: 1024px) {
    margin-bottom:1.5rem;
    font-size: 1.875rem;
    line-height: 40px;
  }
`;

export const SerivcesContent = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
`;